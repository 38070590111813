<template>
    <div class="usage-instructions">
      <h2>How to Use This Site</h2>
      <p>Step 1: Upload your Meet Manager HyTek heatsheet in the upload section.</p>
      <p>Step 2: Wait for the file to be processed, when it is done the download button will become avalible.</p>
      <p>Step 3: Download the processed file in the download section.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HowTo',
  };
  </script>
  
  <style scoped>
  .how-to {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  </style>